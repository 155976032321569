import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Experience from "./pages/Experience";
import Education from "./pages/Education";
import Footer from "./components/Footer";

function App() {
    return (
        <div className="page">
            <Navbar />
            <div className="container" role="main">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/experience" element={<Experience />} />
                    <Route path="/education" element={<Education />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}

export default App;
