import React from "react";
import "./footer.css";
import { ReactComponent as Github } from "../assets/github.svg";
import { ReactComponent as Linkedin } from "../assets/linkedin.svg";

function Footer() {
    return (
        <footer>
            <section className="profile-icons" role="contentinfo">
                <a
                    href="https://github.com/joaobarros1"
                    aria-label="GitHub Profile"
                >
                    <Github />
                </a>
                <a
                    href="https://linkedin.com/in/joaobarros1/"
                    aria-label="LinkedIn Profile"
                >
                    <Linkedin />
                </a>
            </section>
        </footer>
    );
}

export default Footer;
